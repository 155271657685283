import { useState, SyntheticEvent, useRef } from 'react'
import { useMutation } from '@apollo/client'
import { ADD_REF, ALL_REFS } from '../queries'
import { Editor } from './Editor/Editor';
import { $getRoot, LexicalEditor } from 'lexical';
import { CLEAR_EDITOR_COMMAND } from 'lexical';
import { useNavigate } from 'react-router-dom';


const RefForm = ({ setNotification }: { setNotification: (message: string, type: 'success' | 'error') => void }) => {
  const [author, setAuthor] = useState('')
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [link, setLink] = useState('')
  const [linkTitle, setLinkTitle] = useState('')

  const editorRef = useRef<LexicalEditor | null>();

  const [createRef] = useMutation(ADD_REF, {
   // refetchQueries: [ { query: ALL_REFS } ],
    // onError: (error) => {
    //   //const messages = error.graphQLErrors[0].message
    //   //const messages = error
    //   console.log(error.message)
    //   setNotification(error.message, 'error');
    // },
    update: (cache, response) => {
      cache.updateQuery({ query: ALL_REFS }, ({ allReferences }) => {
        return {
          allReferences: allReferences?.concat(response.data.addRef),
        }
      })
    },
  })
  // useEffect(() => {
  //   const latestEditorState = editorRef?.current?.getEditorState();
  //   const textContent = latestEditorState?.read(() =>
  //     $getRoot().getTextContent()
  //   );
  //   setDescription(textContent);
  //   console.log(textContent)
  // }, [editorRef])
  const navigate = useNavigate()
  const submit = async (event: SyntheticEvent) => {
    event.preventDefault()
    
    const latestEditorState = editorRef.current?.getEditorState();
    console.log(latestEditorState);
    const textContent = latestEditorState?.read(() =>
      $getRoot().getTextContent()
    );
    const description = textContent;

    const result = await createRef({ variables: { author, title, description, link, linkTitle } })
    if(result.data) {
    setAuthor('')
    setTitle('')
    setDescription('')
    setLink('')
    setLinkTitle('')
    setNotification(`${title} 추가되었습니다`, 'success')
    navigate('/references')
    }

    //setDescription('')
    //setApps([])

    if (editorRef.current !== null)
    editorRef.current?.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
  }

  // const addApp = () => {
  //   setApps(apps.concat(app))
  //   setApp('');
  // }
//   const handleRadioChange = (event: { target: { value: SetStateAction<string>; }; }) => {
//     setAppType(event.target.value); // Update the selected value state when a radio button is selected
//   };


  return (
    <div style={{marginLeft: '1.5%', marginRight: '1.5%'}}>
      <h2>추가하기</h2>
      <form onSubmit={submit}>
        <div>
          저자 <input value={author}
            onChange={({ target }) => setAuthor(target.value)}
          />
        </div>
        <div>
          제목 <input value={title}
            onChange={({ target }) => setTitle(target.value)}
          />
        </div>
        <div  className="editorWrapper">
          {/* <input value={description}
            onChange={({ target }) => setDescription(target.value)}
          /> */}
          <Editor ref={editorRef} />
        </div>
        {/* <div>어플리케이션: {apps.join(' ')}</div> */}
        {/* <div>
          앱 <input value={app}
            onChange={({ target }) => setApp(target.value)}
          />
          <button onClick={addApp} type="button">
            앱 추가
          </button>
        </div> */}
                <div>
          링크 주소 <input value={link}
            onChange={({ target }) => setLink(target.value)}
          />
        </div>

        <div>
          링크 제목 <input value={linkTitle}
            onChange={({ target }) => setLinkTitle(target.value)}
          />
        </div>
      <hr/>
      <button type='submit' id='submit-button'>추가!</button>
      </form>
    </div>
  )
}

export default RefForm;