import React from 'react';
import { Link } from 'react-router-dom'
import { NavigationDiv } from '../../style';
import { useApolloClient, useMutation } from '@apollo/client';
import { LOGOUT_ALL } from '../../queries';

const Navigation = ({ setToken, token, setNotification }:
    {
        setToken: React.Dispatch<React.SetStateAction<string | null>>, token: string | null,
        setNotification: (message: string, type: 'success' | 'error') => void
    }) => {

    const padding = {
        padding: 5,
        textDecoration: "none",
        color: "inherit"
    }
    // const [logoutOne] = useMutation(LOGOUT_ONE, {
    //     onError: (error) => {
    //         console.log(error)
    //         setNotification(error.message, 'error');
    //     }
    // })
    const [logoutAll] = useMutation(LOGOUT_ALL, {
        onError: (error) => {
            setNotification(error.message, 'error');
        }
    })
    const client = useApolloClient();
    const logout = async () => {
        await logoutAll();
        setToken(null)
        localStorage.clear()
        client.resetStore()
        setNotification('Logout', 'success')
    }

    // const [state, setState] = React.useState({
    //     top: false,
    //     left: false,
    //     bottom: false,
    //     right: false,
    //   });

    // const toggleDrawer =
    //   (anchor: Anchor, open: boolean) =>
    //   (event: React.KeyboardEvent | React.MouseEvent) => {
    //     if (
    //       event.type === 'keydown' &&
    //       ((event as React.KeyboardEvent).key === 'Tab' ||
    //         (event as React.KeyboardEvent).key === 'Shift')
    //     ) {
    //       return;
    //     }

    //     setState({ ...state, [anchor]: open });
    //   };

    // const list = (anchor: Anchor) => (
    //   <Box
    //     sx={{ width:  250 }}//anchor === 'top' || anchor === 'bottom' ? 'auto' :
    //     role="presentation"
    //     onClick={toggleDrawer(anchor, false)}
    //     onKeyDown={toggleDrawer(anchor, false)}
    //   >
    //     <List>
    //       {['참고자료'].map((text, index) => (
    //         <ListItem key={text} disablePadding>
    //           <ListItemButton>
    //             <ListItemIcon>
    //               {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
    //             </ListItemIcon>
    //             <ListItemText primary={text} />
    //           </ListItemButton>
    //         </ListItem>
    //       ))}
    //     </List>
    //     <Divider />
    //     <List>
    //       {[].map((text, index) => (
    //         <ListItem key={text} disablePadding>
    //           <ListItemButton>
    //             <ListItemIcon>
    //               {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
    //             </ListItemIcon>
    //             <ListItemText primary={text} />
    //           </ListItemButton>
    //         </ListItem>
    //       ))}
    //     </List>
    //   </Box>
    // );
    // style={{ position: isScrolled ? 'relative' : 'fixed' }}
    //     <Link style={padding} to="/">
    //     {/* <img  src={logo} style={{height:20, width: 30}} alt="fireSpot"/> */}
    //     <span style={{ color: '#6e5773', fontWeight: 'bold', fontSize: 22 }}>트랩</span>
    //     <span style={{ color: '#24527a', fontWeight: 'bold', fontSize: 22 }}>위키</span>
    //     <div>
    // <span style={{ display: 'block', color: '#888', fontSize: 14 }}>app as trap</span>
    // </div>
    // </Link>

    return (
        <NavigationDiv>
            <Link style={{ ...padding, display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: -8 }} to="/">
                <div style={{ display: 'flex' }}>
                    <span style={{ color: '#6e5773', fontWeight: 'bold', fontSize: 22 }}>트랩</span>
                    <span style={{ color: '#24527a', fontWeight: 'bold', fontSize: 22 }}>위키</span>
                </div>
                <span style={{ color: '#888', fontSize: 14, marginTop: '-5px', textAlign: 'center' }}>app as trap</span>
            </Link>
            <Link style={padding} to="/about">About</Link>
            <Link style={padding} to='/digitaldetox'>디지털디톡스</Link>

            <Link style={padding} to='/os'>OS</Link>
            <Link style={padding} to='/references'>참고자료</Link>
            <Link style={padding} to='/donation'>후원</Link>

            {token ? <Link style={padding} to="/new_principle">+=트랩</Link> : null}
            {token ? <Link style={padding} to="/new_app">+=앱</Link> : null}
            {token ? <Link style={padding} to="/new_reference">+=참고자료</Link> : null}

            <Link style={padding} to='/suggestion'>제보&의견</Link>
            {/* {token ? null : <Link style={padding} to='/signup'>회원가입</Link>} */}
            {/* {token ? <button onClick={logout}>logout</button> :
                null} */}
            {/* <Link style={padding} to="/principles">
                트랩s
            </Link>

            <Link style={padding} to="/apps">앱s</Link> */}
            {token ? <button onClick={logout}>logout</button> :
                null
            }
            {token ? <Link style={padding} to="/for_xml">xml</Link> : null}
            {/* <div style={{ marginLeft: 'auto' }}>
            {(['right'] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <Button style={{position: isScrolled ? 'relative' : 'fixed', right: '10px', top: '5px'}} onClick={toggleDrawer(anchor, true)}><MenuIcon style={{color: 'black', fontSize: '40px'}}/></Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}</div> */}
        </NavigationDiv>
    )
}


export default Navigation;

