import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppType, PrincipleType } from "../types";
import { FixedAppStyledBox, FixedStyledBox, HomeStyleddBox } from "./styled_components/Boxs";
import Notify from "./Notify";

const Home = ({ apps, principles, setNotification }: {
    apps: AppType[], principles: PrincipleType[],
    setNotification: (message: string, type: 'success' | 'error') => void
}) => {
    const padding = {
        textDecoration: "none",
        color: "white",
    };
    const [message, setMessage] = useState<string | null>(null)
    const [messageType, setMessageType] = useState<'success' | 'error' | null>(null);

    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const location = useLocation();

    useEffect(() => {
        document.title = "트랩위키"

        if (location.pathname === "/") {
            setMessage(
                `박스를 누르면 해당 페이지로 이동합니다.\n \n트랩위키는 어플리케이션 디자인을 분석하고,\n \n해결책(디지털디톡스 및 OS)을 고민합니다.`
            )
            setMessageType("success");
            setTimeout(() => {
                setMessage(null)
                setMessageType(null)
            }, 10000)
        }
    }, [document.title]);

    useEffect(() => {
        const canvas = canvasRef.current;

        if (!canvas) return;

        const ctx = canvas!.getContext("2d");
        if (!ctx) return;

        const renderLines = () => {
            const canvasWidth = window.screen.width * 0.4;
            const canvasHeight = 60 * principles.length + 200;

            canvas.width = canvasWidth;
            canvas.height = canvasHeight;

            ctx?.clearRect(0, 0, canvasWidth, canvasHeight);

            apps.forEach((a: AppType, appIndex: number) => {
                a?.used_principles.forEach((p: PrincipleType, principleIndex: number) => {
                    const appY = (appIndex + 1) * 64 - 26;
                    const realPrincipleIndex = principles.findIndex(k => k.id === p.id);
                    const principleY = (realPrincipleIndex + 1) * 64 - 26;
                    let pickedColor;

                    switch (a.appType) {
                        case 'SOCIALMEDIA':
                            pickedColor = '#8ef6e4';
                            break;
                        case 'COMMUNITY':
                            pickedColor = '#5e63b6';
                            break;
                        case 'NEWS':
                            pickedColor = '#118a7e';
                            break;
                        case 'OTT':
                            pickedColor = '#ff847c';
                            break;
                        case 'VIDEO':
                            pickedColor = '#dc2f2f';
                            break;
                        case 'GAME':
                            pickedColor = '#fac70b';
                            break;
                        default:
                            pickedColor = '#d55b3e';
                            break;
                    }

                    ctx?.beginPath();
                    ctx?.moveTo(0, principleY);
                    if (document.documentElement.scrollTop > 90)
                        ctx?.lineTo(canvasWidth, document.documentElement.scrollTop + appY - 63)
                    else if (document.documentElement.scrollTop < 20)
                        ctx?.lineTo(canvasWidth, document.documentElement.scrollTop + appY)
                    else if (document.documentElement.scrollTop < 40)
                        ctx?.lineTo(canvasWidth, document.documentElement.scrollTop + appY - 20)
                    else if (document.documentElement.scrollTop < 60)
                        ctx?.lineTo(canvasWidth, document.documentElement.scrollTop + appY - 40)
                    else if (document.documentElement.scrollTop < 80)
                        ctx?.lineTo(canvasWidth, document.documentElement.scrollTop + appY - 60)
                    else ctx?.lineTo(canvasWidth, document.documentElement.scrollTop + appY - 60)
                    ctx.strokeStyle = pickedColor;
                    ctx?.stroke();
                });
            });
        };


        renderLines();

        // Handle window resize to keep the canvas responsive
        const handleResize = () => {
            renderLines();
        };

        const handleScroll = () => {
            renderLines(); // Update canvas on scroll

            // You can also set appLocationTop or do other actions here if needed
            // const newAppLocationTop = document.documentElement.scrollTop;
            // setAppLocationTop(newAppLocationTop);
        };

        window.addEventListener("resize", handleResize);
        window.addEventListener("scroll", handleScroll); // Listen for scroll events

        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("scroll", handleScroll); // Remove scroll event listener
        };
    }, [apps, principles]);


    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <Notify message={message} messageType={messageType} />
            <div style={{ flex: '1', marginLeft: '1%' }}>
                {principles.map((p: PrincipleType) => p && (
                    <div key={p.id} style={{ marginTop: '10px' }}>
                        <Link to={`/principles/${p.names.koreanName}`} key={p.id} style={padding}>
                            <FixedStyledBox>
                                <div>{p.names.koreanName}</div>
                            </FixedStyledBox>
                        </Link>
                    </div>
                ))}
            </div>

            <canvas ref={canvasRef} style={{ flex: '1' }} />

            <div style={{ flex: '1', marginRight: '1%', position: 'sticky', top: 0 }}>
                {apps?.map((a: AppType) => a && (
                    <div key={a.id} style={{ marginTop: '10px' }}>
                        <Link to={`/apps/${a.names.koreanName}`} style={padding}>
                            <FixedAppStyledBox appType={a.appType}>
                                <div>{a.names.koreanName}</div>
                            </FixedAppStyledBox>
                        </Link>
                    </div>
                ))}
                <br/>
                <br/>
                <Link to={`/digitaldetox`} style={padding}>
                    <HomeStyleddBox appType={"digitaldetox"}>
                        <div>디지털 디톡스</div>
                    </HomeStyleddBox>
                </Link>
                <br/>
                <Link to={`/os`} style={padding}>
                    <HomeStyleddBox appType={"os"}>
                        <div>운영체제별 <br/>대처법</div>
                    </HomeStyleddBox>
                </Link>
                <br/>
                <Link to={`/references`} style={padding}>
                    <HomeStyleddBox appType={"references"}>
                        <div>참고자료</div>
                    </HomeStyleddBox>
                </Link>
            </div>
        </div>
    );
};

export default Home;
